<template>
  <div>
    <PageHeader :items="items" :optionalItems="optionalItems"
      ><template #buttons>
        <div class="d-flex gap-1" v-if="$can('backoffice-company-logo.upload')">
          <span style="color: #05fffa" class="d-flex align-items-center">{{
            $t("Upload Your Logo")
          }}</span>
          <input
            type="file"
            @change="handleFileUpload"
            accept="image/*"
            ref="fileInput"
            style="min-width: 100px"
          />
        </div>
        <div v-if="image.url">
          <img :src="image.url" style="width: 90px; height: 40px" />
          <feather-icon
            v-if="$can('backoffice-company-logo.delete')"
            style="color: red"
            @click="deleteLogo()"
            class="ml-1 cursor-pointer"
            size="16"
            icon="TrashIcon"
          />
        </div>
      </template>
    </PageHeader>
    <div class="custom-search d-flex justify-content-between mb-1">
      <b-form-group>
        <div class="d-flex align-items-center">
          <span class="text-nowrap"> {{ $t("Rows per page") }} </span>
          <b-form-select
            v-model="pageLength"
            :options="['25', '50', '100']"
            class="ml-1"
            @input="(value) => onPerPageChange({ pageLength: value })"
          />
        </div>
      </b-form-group>
    </div>
    <h3 style="font-size: 16px">
      {{ $t("Your personal registration code") }} :
      <a
        :href="`${referralServiceBaseUrl}/register?companyId=${companyId}`"
        target="_blank"
      >
        {{ `${referralServiceBaseUrl}/register?companyId=${companyId}` }}
      </a>
    </h3>
    <!--==================================-->
    <!--==================================-->
    <div class="dextop-table-view">
      <div class="table-responsive api-keys-table mt-2">
        <!-- table -->
        <vue-good-table
          styleClass="vgt-table striped"
          :columns="columns"
          :fixed-header="false"
          :rows="rows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
            trigger: 'enter',
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-per-page-change="onPerPageChange"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'costs'">{{
              $formatter(props.row.costs)
            }}</span>
            <span v-else-if="props.column.field === 'Company.companyName'">
              {{ props.row.reseller }}
            </span>
            <span v-else-if="props.column.field === 'createdAt'">
              <p class="">
                {{ $dateFormatter(props.row.createdAt, $i18n.locale) }}
              </p>
            </span>
            <span
              v-else-if="props.column.field === 'invoiceSum'"
              style="white-space: nowrap"
            >
              {{
                $formatter(
                  props.row.invoiceSum,
                  $i18n.locale,
                  "EUR",
                  false,
                  2,
                  2
                )
              }}
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-end flex-wrap">
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="totalRecords"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => onPageChange({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </div>
    <!--==================================-->
    <!--==================================-->
    <div class="reponsive-view-cards">
      <b-row>
        <b-col cols="12" lg="4" md="6" v-for="row in rows" :key="row.id">
          <div class="card">
            <div class="card-header">
              <h5>{{ row.companyNumber }}</h5>
            </div>

            <div class="card-body pt-0">
              <ul class="card-list">
                <li>
                  <div class="left">
                    <strong>{{ $t("Company Name") }}: </strong>
                  </div>
                  <div class="right">
                    <span>{{ row.companyName }}</span>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <strong>{{ $t("Registration Date") }}: </strong>
                  </div>
                  <div class="right">
                    <span>{{
                      $dateFormatter(row.createdAt, $i18n.locale)
                    }}</span>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <strong>{{ $t("Overall Invoice Amount") }}: </strong>
                  </div>
                  <div class="right">
                    <span>{{
                      $formatter(
                        row.invoiceSum,
                        $i18n.locale,
                        "EUR",
                        false,
                        2,
                        2
                      )
                    }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="d-flex justify-content-center mt-2">
            <b-pagination
              :value="1"
              :total-rows="totalRecords"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => onPageChange({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-col>
      </b-row>
    </div>
    <!--==================================-->
    <!--==================================-->
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Multiselect from "vue-multiselect";
import { props } from "vue-prism-component";

export default {
  components: {
    PageHeader,
    Multiselect,
  },

  computed: {
    optionalItems() {
      return {
        createBtn1: {
          show: false,
          text: this.$t(""),
          icon: "PlusIcon",
          path: "",
          permission: "",
        },
      };
    },
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("Referrals"),
          active: true,
        },
      ];
    },
    columns() {
      return [
        {
          label: this.$t("Company Number"),
          field: "companyNumber",
        },
        {
          label: this.$t("Company Name"),
          field: "companyName",
        },
        {
          label: this.$t("Registration Date"),
          field: "createdAt",
        },
        {
          label: this.$t("Overall Invoice Amount"),
          field: "invoiceSum",
          sortable: false,
        },
      ];
    },
  },
  data() {
    return {
      totalRecords: 0,
      pageLength: 25,
      page: 1,
      form: {
        sortBy: "createdAt",
        sortOrder: "desc",
      },
      image: {},
      rows: [],
      companyId: localStorage.getItem("company_id"),
      referralServiceBaseUrl: process.env.VUE_APP_REFERRAL_BASEURL || "",
      searchTerm: "",
    };
  },

  async created() {
    this.loadItems();
    this.loadCompanyLogo();
  },

  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file instanceof Blob) {
        let reader = new FileReader();

        const readFileAsBase64 = () => {
          return new Promise((resolve, reject) => {
            reader.readAsDataURL(file);
            reader.onload = () => {
              const requiredData = reader.result;
              const data = {
                name: file.name,
                size: file.size,
                base64: requiredData,
              };
              resolve(data);
            };
            reader.onerror = (error) => {
              reject(error);
            };
          });
        };
        (async () => {
          try {
            const data = await readFileAsBase64();
            await this.$store.dispatch("customers/uploadLogo", {
              image: data,
            }).then(async (res) => {
              await this.loadCompanyLogo()
            });


          } catch (error) {
            console.error("Error reading file:", error);
          }
        })();
      }
    },
    async deleteLogo() {
     const confirmed = await this.showConfirmationDialog({
        title: this.$t("Do you want to delete the logo?"),
        subText: this.$t("Yes delete it!"),
      });

      if (confirmed) {
        try {
          this.$store.commit("showLoader", true);
          await this.$store.dispatch("customers/deleteLogo");
          this.image = {}
        } catch (e) {
          console.error("api error ___", e);
        } finally {
          this.$store.commit("showLoader", false);
        }
      }
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this, newProps);
    },

    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ pageLength: params.pageLength });
      this.loadItems();
    },
    async loadCompanyLogo() {
      await this.$store
        .dispatch("customers/show", this.companyId)
        .then((res) => {
          let data = res?.data?.modelData;
          this.image = data.image ?? {};
        });
    },
    onSortChange(params) {
      this.form.sortOrder = params[0].type;
      if (params[0].type == "none") this.form.sortOrder = "asc";
      this.form.sortBy = params[0].field;
      if (params[0].field == "salesPartner") {
        this.form.sortBy = "Company.companyName";
      }
      this.loadItems();
    },

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        this.$store.commit("showLoader", true);
        let response = await this.$store.dispatch("customers/listReferrals", {
          page: this.page,
          ...this.form,
          perPage: this.pageLength,
        });
        this.rows = response?.data?.data;
        this.totalRecords = response?.data?.total;
      } catch (e) {
        console.error("api error ___", e);
      } finally {
        this.$store.commit("showLoader", false);
      }
    },
  },
};
</script>
